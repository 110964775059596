import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CartesService {
  constructor(private http: HttpClient) { }

  all() {
    return this.http.get< any[]>(`${environment.walletApi}/cartes`);
  }
  allStatus(status) {
    return this.http.get< any[]>(`${environment.walletApi}/cartes?status=${status}`);
  }
  getCarteByClientId(clientID) {
    return this.http.get< any[]>(`${environment.walletApi}/cartes?clientID=${clientID}`);
  }

  get(id) {
    return this.http.get< any[]>(`${environment.walletApi}/cartes/${id}`);
  }
  addCarte(data){
    return this.http.post< any[]>(environment.walletApi + '/clients/carte/ajout',data);
   }
  getDistributionCaisse(page,query) {
    return this.http.get<any[]>(`${environment.walletApi}/distribution_carte_caisses?_page=${page}${query}`);
  }
  setStatusDistribution(id, state){
    return this.http.put<any[]>(`${environment.walletApi}/distribution_carte_caisses/${id}/validate`,state);

  }
}
