import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FundService {

  constructor(private http: HttpClient) {
  }

  all() {
    return this.http.get<any[]>(`${environment.apiReseauUrl}/mouvement_caisses/mesdemandes`);
  }

  create(params) {
    return this.http.post<any[]>(`${environment.apiReseauUrl}/mouvement_caisses`, params);
  }

  searchRef(ref) {
    return this.http.get<any[]>(`${environment.apiReseauUrl}/mouvement_caisses?reference=${ref}`);
  }


  validate(id, params) {
    return this.http.put<any[]>(`${environment.apiReseauUrl}/mouvement_caisses/valider/${id}`, params);
  }
  savePiececonformite(code_caisse,data){
    return this.http.post<any[]>(`${environment.apiReseauUrl}/piece_conformites/${code_caisse}`, data);
  }
  updatePiececonformite(id,data){
    return this.http.put<any[]>(`${environment.apiReseauUrl}/piece_conformites/${id}`, data);
  }
  getPieceConformite(query){
    return this.http.get<any[]>(`${environment.apiReseauUrl}/piece_conformites${query}`,{});
  }
}
